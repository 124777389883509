<template>
  <div
    v-if="flatpage"
    class="row"
  >
    <div class="ten wide column">
      <h1>{{ flatpage.title }}</h1>
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="flatpage.content" />
      <!--eslint-enable-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Default',

  computed: {
    ...mapState(['staticPages']),
    flatpage() {
      if (this.staticPages) {
        return this.staticPages.find(
          (page) => page.url === `/${this.$route.name}/`
        );
      }
      return null;
    },
  },
};
</script>